<!-- =========================================================================================
	File Name: ItemGridView.vue
	Description: Item Component - Grid VIew
	----------------------------------------------------------------------------------------
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <!-- <div class="item-grid-view vx-row match-height">
      <div class="vx-col" :class="gridColumnWidth" v-for="item in items" :key="item.objectID"> -->
  <vx-card class="grid-view-item mb-base overflow-hidden" fixedHeight v-on="$listeners" v-if="item">
    <template slot="no-body">

      <!-- ITEM IMAGE -->
      <div class="item-img-container bg-white h-64 flex items-center justify-center mb-4 cursor-pointer" @click="navigate_to_detail_view">
        <img :src="item.images ?item.images[0].image: ''" :alt="item.name" class="grid-view-img px-4 mt-4">
      </div>
      <div class="item-details px-4">

        <!-- RATING & PRICE -->
        <div class="flex">
          <!--                            <div class="text-warning border border-solid border-warning flex py-1 px-2 rounded">-->
          <!--                                <span class="text-sm mr-1">{{ item.rating }}</span>-->
          <!--                                <feather-icon icon="StarIcon" svgClasses="h-4 w-4" />-->
          <!--                            </div>-->
          <p class="font-bold mr-4" v-if="item.originalPrice"><s>${{ item.originalPrice | formatDecimals }} </s></p>
          <p class="font-bold">${{ item.price | formatDecimals }}</p>
        </div>

        <!-- TITLE & DESCRIPTION -->
        <div class="my-4">
          <h6 class="truncate font-semibold mb-1 hover:text-primary cursor-pointer" @click="navigate_to_detail_view">{{ item.name }}</h6>
          <p class="text-sm">By <span class="font-semibold cursor-pointer mb-4" v-if="item.supplier">{{ item.supplier.name }}</span></p>

          <p class="item-description truncate text-sm mb-2">{{ item.description }}</p>
          <p class="font-semibold">Categories</p>
          <p class="item-description text-sm mb-2 category-description"><span v-for="(category,index) in item.categories" :key="category._id">{{category.name}}<span v-if="index+1 !== item.categories.length">, </span></span> </p>

          <p>Qty:<span style="display: flex">
            <vs-input-number
              v-model="quantity"
              v-bind:min="item.inventory.requiredQuantity"
              :step="1"
              @input="selectedChangeQuantity(quantity, item)"/>
          </span>
            <span class="text-danger" style="font-size: 0.75em">
              {{
                errors.firstById(item._id)
              }}
            </span>
          </p>
        </div>
      </div>

      <!-- SLOT: ACTION BUTTONS -->
      <slot name="action-buttons" :item2="item"/>
    </template>
  </vx-card>
  <!--  </div>
</div> -->
</template>

<script>
export default{
  data() {
    return {
      quantity: 1
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    index:{
      type: Number,
      default: 0
    }
  },
  created() {
    // this.item.canAdd = false;
    if (this.item.inventory.requiredQuantity !== undefined && this.item.inventory.requiredQuantity !== 0) {
      this.quantity = this.item.inventory.requiredQuantity;
    } else {
      this.quantity = 1;
      this.item.inventory.requiredQuantity = 1;
    }
    this.item.selectedQuantity = this.quantity;
  },

  methods: {
    navigate_to_detail_view() {
      this.$router.push({name: 'ClinicProductDetail', params: {id: this.item._id }})
        .catch(() => {})
    },
    selectedChangeQuantity(quantity, data){
      this.$emit('quantityChange',{index:this.index,quantity:this.quantity})
      if(this.quantity < data.inventory.requiredQuantity) {
        this.errors.add({
          field: "MinimumQuantityLimit",
          msg: "Minimum order quantity of " + data.name + " is "  + data.inventory.requiredQuantity,
          id: data._id
        });
        // this.item.canAdd = false;
      } else {
        this.errors.removeById(data._id);
        this.item.selectedQuantity = quantity;
        // this.item.canAdd = true;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.category-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.grid-view-item {
  .grid-view-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    transition: .35s;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(0,0,0,.25);

    .grid-view-img{
      opacity: 0.9;
    }
  }
}
</style>
